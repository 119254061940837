import React, { useState, Fragment } from 'react'

import { g } from '@storybook/theming/dist/create-8dd9d29b'

import { format, nextSunday, nextMonday } from 'date-fns'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Paragraph, Link, Button, Heading, SmallLoader, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { EditSubscriptionModal } from '@components/organisms'
import { ResponsiveProperty, ResponsivePXValue, theme } from '@components/Theme'
import {
  useUserCartQuery, useUserDetailsQuery, UserSubscriptionFragment, useActivateSubscription, UserDetailsFragment,
  RegisteredUserDetailsFragment,
} from '@hooks/api'
import { InlineInputProps, ContentCard, ContentCardHeaderEnum, SectionLoading } from '@molecules/index'
import { UserSubscriptionStatusEnum, NumberOfPortionsEnum, ProductOrderTypeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { Toggle } from '../molecules/controls/Toggle'

const NoSubscriptionsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', '16px 32px')}
  div{
    margin: 0;
    align-self: flex-start
  }
`
const NoSubscriptionHeading = styled.div`
  ${ResponsivePXValue('padding-top', '16px')}
  h5{
    ${ResponsivePXValue('padding-bottom', '0')}
  }
`

const NoSubscriptionText = styled.div`
  div{
    ${ResponsivePXValue('margin-top', '0')}
  }
  ${ResponsivePXValue('padding', '16px 0')}
`

const ActivateSubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  width: 100%;
`

const RowContainer = styled.div`
  position: relative;
  display: flex;
  ${ResponsivePXValue('padding', { tablet: '0 24px', desktop: '0 32px' })}
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
`

const ToggleContainer = styled.div`
  ${ResponsivePXValue('padding', { tablet: '0 16px 0 0', desktop: '0 24px 0 0' })}
  ${ResponsivePXValue('margin', { mobile: '16px 16px 0' })}
`

const SpacedContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px 0', desktop: '32px 0' })}
`
const SubscriptionMessageContainer = styled.div`
  ${ResponsivePXValue('padding-bottom', { mobile: '16px' })}
  div{

    word-wrap: break-word;

    ${ResponsiveProperty('width', { mobile: '80vw', tablet: 'initial', desktop: 'initial' })}
    ${ResponsivePXValue('padding', '0')}
    ${ResponsivePXValue('margin', '0')}
  }
`
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.whites.alabaster};
  ${ResponsivePXValue('padding', { mobile: '24px 16px 40px 16px', tablet: '24px 40px 40px 40p', desktop: '32px 40px 40px 40px' })}
  div{
    margin: 0vw;
    align-self: flex-start;
  }
  h5{
    padding-top: 0;
  }
`

const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '16px 16px 18px 16px', tablet: '24px 40px 40px 40px', desktop: '16px 32px 18px 32px' })};
`

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  
`

const TextContainer = styled.div`
  div{
    margin: 0;
    ${ResponsivePXValue('padding-bottom', { mobile: '8px', tablet: '6px', desktop: '10px' })}
  }

  .t1-button {
    cursor: pointer;
    color: ${(props): string => props.color ? props.color : props.theme.colors.greys.liteCodGrey};
    text-align: 'left';
    line-height: 1.7;
    font-family: 'gordita';
    font-weight: 400;
    ${ResponsivePXValue('font-size', '12px')}
  }

  .t2-button {
    cursor: pointer;
    color: ${(props): string => props.color ? props.color : props.theme.colors.greys.liteCodGrey};
    text-align: 'left';
    line-height: 1.7;
    font-family: 'gordita';
    ${ResponsivePXValue('font-size', '12px')}
  }
`
const noSubscriptionContent = {
  title: 'Welcome to the future of food.',
  description: 'Select a category that matches your lifestyle and we’ll recommend dishes we know you’ll love. Choose how many people you want to cook for (1, 2, or 4), how often you’d like to cook each week (2, 3 or 4 times). You can pause your subscription when you’ve got other plans, or cancel it at any time.',
}
interface SubscriptionsState {
  activationLoading: boolean
  editSubscriptionModalOpen: boolean
  notifyMarketProductsModalOpen: boolean
  activeId: string | null
  pauseDate: string
  pauseToggle: boolean
}

const DEFAULT_STATE: SubscriptionsState = {
  activationLoading: false,
  editSubscriptionModalOpen: false,
  notifyMarketProductsModalOpen: false,
  activeId: null,
  pauseDate: '',
  pauseToggle: false,
}

const seo = {
  name: 'User Dashboard',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function Subscriptions(): JSX.Element {

  const config = useConfig()
  const navigate = useNavigate()
  const [activateSubscription] = useActivateSubscription()

  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [state, setState] = useState<SubscriptionsState>({ ...DEFAULT_STATE })
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const isActiveSubscription = subscription?.status === UserSubscriptionStatusEnum.ACTIVE

  const getCartMarketProducts = () => {
    return userCartData?.currentUser?.activeCart?.cartItems?.filter((item) => item.product?.__typename === 'MarketProduct' && item.product?.warehouses?.some((warehouse) => warehouse.orderType === ProductOrderTypeEnum.SUBSCRIPTION)) || []
  }

  const cartMarketProducts = getCartMarketProducts()

  const setLoading = (loading: boolean) => {
    setState((prevState) => update(prevState, {
      activationLoading: { $set: loading },
    }))
  }

  const _openPauseModal = () => {
    if (isActiveSubscription) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION, null, true)
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION)
    }
  }

  const openActionModal = () => {
    if (cartMarketProducts.length > 0) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.NOTIFY_MARKET_PRODUCTS)
    } else {
      _openPauseModal()
    }
  }

  const openEditModal = () => {
    setState((prevState) => update(prevState, {
      editSubscriptionModalOpen: { $set: true },
    }))
  }

  const closeEditModal = () => {
    setState((prevState) => update(prevState, {
      editSubscriptionModalOpen: { $set: false },
    }))
  }

  const _handleSubscriptionButtonClick = () => {
    if (isActiveSubscription) {
      navigate('/me/orders')
    } else {
      openActionModal()
    }
  }

  const _handleActivateSubscription = async () => {
    setLoading(true)
    await activateSubscription({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.subscription?.id,
        reset: !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox,
      },
      refetchQueries: SiteHelper.getUserRefetchQueries(),
      awaitRefetchQueries: true,
    })
    setLoading(false)
  }

  const _handleChangeSubscription = () => {
    if (isActiveSubscription) {
      openActionModal()
    } else {
      _handleActivateSubscription()
    }
  }

  const convertNumberOfPortions = (numberOfPortions: NumberOfPortionsEnum): string => {
    switch (numberOfPortions) {
      case NumberOfPortionsEnum.SERVES_1:
        return '1'
      case NumberOfPortionsEnum.SERVES_2:
        return '2'
      case NumberOfPortionsEnum.SERVES_3:
        return '3'
      case NumberOfPortionsEnum.SERVES_4:
        return '4'
    }
  }

  const rows: InlineInputProps[] = subscription
    ? [{
      title: 'Selected meal kit category',
      text: [subscription.category.title],
    }, {
      title: 'Your current plan serves',
      text: [convertNumberOfPortions(subscription.numberOfPortions)],
    }, {
      title: 'Number of dishes per week',
      text: [String(subscription.numberOfMeals)],
    }, {
      title: 'Delivery day',
      text: [subscription.deliveryOption.split(' ')
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')],
      rule: false,
    }]
    : []

  const headerContent = {
    title: 'MEAL KIT SUBSCRIPTION',
    description: 'UCOOK Meal Kits are designed to make your life that much easier. When you subscribe, you’ll receive a weekly delivery with everything you need to get cooking. ',
  }
  const resumeDate = userCartData?.currentUser?.activeMenu?.subscription?.resumeDate
  const nextBillingDate = new Date(resumeDate)
  nextBillingDate.setDate(nextBillingDate.getDate() + 7)
  const deliveryDate = subscription?.deliveryOption === 'SUNDAY' ? nextSunday(new Date(nextBillingDate)) : nextMonday(new Date(nextBillingDate))
  const formattedDeliveryDate = isNaN(deliveryDate.getDate()) ? '' : format(deliveryDate, 'E dd MMM yyyy')
  const subscriptionMessage = isActiveSubscription ? 'SUBSCRIPTION ACTIVE' : resumeDate ? `YOUR NEXT BILLING DATE WILL BE ${format(new Date(nextBillingDate), 'E dd MMM yyyy')}` : 'SUBSCRIPTION PAUSED'
  const deliveryMessage = `YOUR NEXT DELIVERY DATE WILL BE ${formattedDeliveryDate}`
  const subscriptionButtonMessage = isActiveSubscription ? 'UPCOMING ORDER' : ' EDIT DELIVERY DATE'

  let row: InlineInputProps
  let textItem: string
  let index: number

  return (
    <Fragment>
      <SearchEngineOptimization seo={seo} />
      <EditSubscriptionModal
        open={state.editSubscriptionModalOpen}
        onClose={closeEditModal} />
      <Choose>
        <When condition={userDetailsLoading || userCartLoading}>
          <ContentCard title={headerContent.title} key={headerContent.title} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
            <SectionLoading />
          </ContentCard>
        </When>
        <When condition={!subscription || subscription.status === UserSubscriptionStatusEnum.PENDING || subscription.status === UserSubscriptionStatusEnum.CANCELLED}>
          <ContentCard headerType={ContentCardHeaderEnum.IMAGE} image='CHOOSE-A-PLAN'>
            <NoSubscriptionsContentContainer>
              <NoSubscriptionHeading>
                <Heading variant='h5' align='left'>{noSubscriptionContent.title}</Heading>
              </NoSubscriptionHeading>
              <NoSubscriptionText>
                <Paragraph variant='p1' align='left'>{noSubscriptionContent.description}</Paragraph>
              </NoSubscriptionText>
              <Button
                title='CHOOSE A PLAN'
                onClick={() => { navigate('/meal-kit/plans') }} />
            </NoSubscriptionsContentContainer>
          </ContentCard>
        </When>
        <Otherwise>
          <ContentCard
            title={headerContent.title}
            description={headerContent.description}
            headerType={ContentCardHeaderEnum.STANDARD}>
            <Fragment>
              <ActivateSubscriptionContainer>
                <RowContainer>
                  <ToggleContainer>
                    <Toggle
                      selected={subscription?.status === UserSubscriptionStatusEnum.ACTIVE}
                      onChange={_handleChangeSubscription} />
                  </ToggleContainer>
                  <SpacedContent>
                    <SubscriptionMessageContainer>
                      <Paragraph variant='p2' >{subscriptionMessage.toUpperCase()}</Paragraph>
                      <If condition={!!resumeDate}>
                        <Paragraph variant='p2' >{deliveryMessage.toUpperCase()}</Paragraph>
                      </If>
                    </SubscriptionMessageContainer>
                    <Link
                      color={theme.colors.slates.ash}
                      onClick={_handleSubscriptionButtonClick}>{subscriptionButtonMessage} </Link>
                  </SpacedContent>
                  <If condition={state.activationLoading}>
                    <OverlayContainer>
                      <LoaderBox>
                        <SmallLoader color={theme.colors.oranges.coral} />
                      </LoaderBox>
                    </OverlayContainer>
                  </If>
                </RowContainer>
              </ActivateSubscriptionContainer>
              <DetailsContainer>
                <Heading variant='h5'>Subscription Details</Heading>
                <Link
                  bold
                  color={theme.colors.oranges.coral}
                  onClick={openEditModal}>EDIT YOUR PLAN</Link>
              </DetailsContainer>
              <For each='row' of={rows} index='index'>
                <ChildrenContainer key={index}>
                  <Content>
                    <TextContainer>
                      <Link bold className='t2-button' key={row.title} onClick={openEditModal}>{row.title}</Link>
                      <Spacer universal='4px' />
                      <For each='textItem' of={row.text}>
                        <Link className='t1-button' key={textItem} onClick={openEditModal}>{textItem} </Link>
                      </For>
                    </TextContainer>
                  </Content>
                </ChildrenContainer>
              </For>
            </Fragment>
          </ContentCard>
        </Otherwise>
      </Choose>
    </Fragment>
  )

}
